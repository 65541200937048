@import url('https://fonts.googleapis.com/css2?family=Teachers:ital,wght@0,400..800;1,400..800&display=swap');

@font-face {
  font-family: 'UniversReg'; /* Name your font family */
  src: url('../public/fonts/UniversRegular.ttf'); /* Locate the .ttf file within your directory*/
}

html {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  width: 0;
}

.App {
  padding: 0;
  transition: 2s ease;
  /* font-family: "Teachers", sans-serif; */
  font-family: "UniversReg", sans-serif;
  font-weight: 500;
  height: 100dvh;
  width: 100vw;
}

a {
  transition: .5s;
}

a:hover {
  opacity: .5;
  transition: .5s;
}

.header {
  position: fixed;
  cursor: pointer;
  margin: 24px;
  font-size: 1rem;
  font-weight: 500;
  z-index: 1;
}

.header:hover {
  opacity: 0.5;
}

.img-container {
  width: 100vw;
  display: flex;
  object-fit: contain;

  transition: 2s ease;
}

.img-container img {
  position: relative;
  height: auto;
  max-height: 80vh;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .img-container {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .img-container {
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .img-container {
    margin-bottom: 150px;
  }
}
